@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ProductCard";

#{$selector} {
  position: relative;

  &__wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 !important;
  }

  &__header {
    &__tag, &__icon {
      @include text("tag");
      position: absolute;
      z-index: 1;
      margin: $space-md;
      padding: $space-xs $space-lg;
      color: $text-primary;
      background-color: $background-secondary;
      border-radius: 1rem;
    }

    &__icon {
      right: 0;
      padding: 0;
      color: $background-primary;
      background-color: transparent;
    }
  }

  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 250px;
    padding: $space-md;
    overflow: hidden;
    background-color: $background-lightgray;

    &__lazy {
      position: relative !important;
      align-self: center;
      max-width: 250px;
      min-height: 250px;
      max-height: 250px;
      padding: $space-lg $space-sm $space-sm;
      object-fit: cover;
    }

    &__lazy--has-link {
      cursor: pointer;
      transition: transform 0.2s;

      @include when-hovered {
        transform: scale(1.1);
      }
    }

    &__placeholder {
      width: 5em !important;
      min-height: 258px;
      max-height: 258px;
    }

    &__placeholder--has-link {
      cursor: pointer;
      transition: transform 0.2s;

      @include when-hovered {
        transform: scale(1.1);
      }
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $space-md;
  }

  &__title {
    @include text("component/productCard/title");
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--has-link {
      cursor: pointer;

      @include when-hovered {
        color: $text-primary;
      }
    }

    &__link {
      text-decoration: none;
    }
  }

  &__note {
    @include text("meta");
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.6;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__footer:has(a:only-child) {
    justify-content: flex-end;
  }

  &__price__wrapper {
    display: flex;
    margin-bottom: 0 !important;
  }

  &__price {
    @include text("component/productCard/price");

    &__discount {
      @include text("component/productCard/price");
      color: $text-error;
    }

    &--has-discount {
      padding-left: $space-md;
      text-decoration: line-through;
      opacity: 0.6;
    }
  }

  &__unit {
    @include text("meta");
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $space-xs;
    background-color: transparent;
    border: 2px solid $border-primary;
    border-radius: 5px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.5s;

    @include when-hovered {
      opacity: 0.5;
    }
  }
}
