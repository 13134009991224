@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartOverview";

#{$selector} {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: $space-3xl;

    @include screenSmallerThan($screen-xl) {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }
  }

  &__items {
    width: 55%;
    padding: $space-lg;

    @include screenSmallerThan($screen-xl) {
      width: 100%;
    }
  }

  &__summary {
    width: 45%;
    padding: $space-lg;

    @include screenSmallerThan($screen-xl) {
      width: 100%;
    }
  }

  &__noData {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}
